/* You can add global styles to this file, and also import other style files */
// set the variables you wish to change
$primary: #5C80BC !default;



.fa-sort,
.fa-percent,
.fa-euro {
    color:#CDD1C4;
    cursor:pointer;
}

.fa-check, .fa-caret-square-o-up {
    color:#6D9F71;
}

.fa-remove, .fa-caret-square-o-down{
    color:#D62246;
}

.error {
    color: #D62246;
}

.front-card {
    height:150px;
    background-repeat:no-repeat;
    background-size:cover;
    width:90%;
    margin:0 auto;
    margin-top:20px;
}

.overzicht {
    background-image:url('/assets/pictures/verkopenEnAankopen.jpg');
}

.rekeningen {
    background-image:url('/assets/pictures/verkopenOverzicht.jpg');
}

.leveranciers {
    background-image:url('/assets/pictures/leveranciersOverzicht.jpg');
}

.offertes {
    background-image:url('/assets/pictures/offerte.jpg');
}

.financieel {
    background-image:url('/assets/pictures/financieel.jpg');
}

.facturen {
    background-image:url('/assets/pictures/facturen.jpg');
}

.handelenwij {
    background-image:url('/assets/pictures/handelenwij.jpg');
    background-repeat:no-repeat;
    background-position:center;
    width:100%;
    height:550px;
}

.btn:hover {
    cursor:pointer;
}

.fb-page {
    height:600px;
}

.container {
    max-width:1280px;
}

.current-year {
  border:2px solid #e9e3e6;
  background-color: #fdfbfc;
  border-radius: 10px;
  font-size:1.25rem;
}

.current-year h2 {
  margin-left:20px;
  padding-top:20px;
}

.current-year table {
  width: calc(100% - 40px);
  margin:0 auto;
}

.current-year .table thead th {
  border-bottom:2px solid #629460;
}

.invalid-input {
  border: 2px solid red;
}

.carousel-item:after {
  content:"";
  display:block;
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  background:rgba(0,0,0,0.8);
  height:140px;
}

.facturen-img {
  background-image:url('assets/pictures/facturen.jpg'); height:500px; background-repeat:no-repeat;
  background-position:center top;
}

.inventaris-img {
  background-image:url('assets/pictures/inventaris.jpg'); height:500px; background-repeat:no-repeat;
  background-position:center top;
}

.verkopen-img {
  background-image:url('assets/pictures/verkopenOverzicht.jpg'); height:500px; background-repeat:no-repeat;
  background-position:center top;
}

.btw-overzicht-img {
  background-image:url('assets/pictures/btw-overzicht.jpg'); height:500px; background-repeat:no-repeat;
  background-position:center top;
}

.klantenlisting-img {
  background-image:url('assets/pictures/klantenlisting.jpg'); height:500px; background-repeat:no-repeat;
  background-position:center top;
}

.iclisting-img {
  background-image:url('assets/pictures/iclisting.jpg'); height:500px; background-repeat:no-repeat;
  background-position:center top;
}

.leveranciers-img {
  background-image:url('assets/pictures/leveranciersOverzicht.jpg'); height:500px; background-repeat:no-repeat;
  background-position:center top;
}

.dashboard-img {
  background-image:url('assets/pictures/verkopenEnAankopen.jpg'); height:500px; background-repeat:no-repeat;
  background-position:center top;
}

.fa-sort:hover {
  color:black;
}

@media only screen and (max-width: 1024px) {
  .facturen-img,
  .iclisting-img,
  .inventaris-img,
  .verkopen-img,
  .btw-overzicht-img,
  .klantenlisting-img,
  .leveranciers-img,
  .dashboard-img {
    background-position:left top;
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@include media-breakpoint-up(md) {
  .nav-item > .nav-link {
    display:inline;
    vertical-align: -webkit-baseline-middle;
  }

  .nav-tabs .nav-item > .nav-link {
    display:block;
  }
}

@include media-breakpoint-down(md) {
  div.current-year {
    th,td {
      font-size:0.9em;
    }
    th {
      padding:0;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

div.current-year {
  th,td {
    text-align:center;
  }
}

.dropdown-menu {
  top:125%;
}
